import React from "react";
import Image from "next/legacy/image";
import classNames from "classnames";
import { isValid } from "date-fns";
import { Link } from "../../atoms";
import imageLoader from "../../utils/imageLoader";
import { useAppState } from "../../utils";
import "./BlogCard.scss";

type Size = "hero" | "large" | "small";

export interface BlogCardProps {
  size?: Size;
  badge?: string;
  className?: string;
  image?: string;
  imageMeta?: string;
  title: string;
  date?: Date;
  url: string;
  target: "_blank" | "_self";
  tabIndex?: number;
  imagePriority?: boolean;
}

export const BlogCard: React.FC<BlogCardProps> = ({
  size = "small",
  badge,
  image = "https://resources.travellocal.com/images/missing-image.jpg",
  imageMeta = "",
  title,
  date,
  url,
  target,
  tabIndex,
  className,
  imagePriority,
}) => {
  const { appState } = useAppState();

  const locale = appState?.locale;
  // Workaround for date-fns.format() interpreting the date as local time.
  // We only care about the date components so create a new local date to avoid timezone shift between server and client.
  // Inspired by https://github.com/date-fns/date-fns/issues/489#issuecomment-357732898
  let dateWithoutTime: Date | null = null;
  if (date && isValid(date)) {
    dateWithoutTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  const blogImageSizes = {
    hero: "90vw",
    large: "40vw",
    small: "(max-width: 768px) 90vw, 420px",
  };

  return (
    <article
      className={classNames(
        "blog-card is-flex is-flex-direction-column",
        className
      )}
    >
      <div
        className={classNames(
          "blog-card__image-wrapper",
          `blog-card__image-wrapper--${size}`
        )}
      >
        <div className="image-container">
          <Image
            src={image}
            alt={imageMeta}
            layout="fill"
            sizes={blogImageSizes[size]}
            quality="50"
            objectFit="cover"
            loader={imageLoader}
            priority={imagePriority}
          />
        </div>
      </div>
      <div
        className={classNames(
          "blog-card__content px-5 pb-5 is-flex is-flex-direction-column is-justify-content-space-between",
          `blog-card__content--${size}`,
          { "pt-6": size !== "small" }
        )}
      >
        <h3
          className={classNames(
            "title",
            { "mb-6 is-4": size === "small" },
            { "mb-2 is-3": size === "large" },
            { "mb-2 is-2 is-size-3-mobile": size === "hero" }
          )}
        >
          {title}
        </h3>
        {dateWithoutTime && (
          <p>
            {dateWithoutTime.toLocaleDateString(locale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        )}
      </div>
      {size === "hero" && !!badge && (
        <div className="badge is-size-6 is-size-7-mobile px-4 py-2 is-uppercase">
          {badge}
        </div>
      )}
      <Link
        tabIndex={tabIndex}
        className="card-link"
        href={url}
        target={target}
      />
    </article>
  );
};

BlogCard.displayName = "BlogCard";
