import React, { ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Icomoon, Icon, Loader } from "../../atoms";
import { useTranslation } from "react-i18next";
import "./InlineForm.scss";

type InlineCallback = (e: React.FormEvent<HTMLFormElement>) => Promise<"success" | "issue">;

type InlineFormProps = {
  editingFields: JSX.Element | ReactNode | (JSX.Element | ReactNode)[];
  previewFields: JSX.Element | ReactNode | (JSX.Element | ReactNode)[];
  editButtonLabel: string;
  saveButtonLabel: string;
  dataTrackingIdEditButton?: string;
  dataTrackingIdSaveButton?: string;
  dataTrackingIdEditing?: string;
  onSubmit: InlineCallback;
};

export const InlineForm = ({
  editingFields,
  previewFields,
  editButtonLabel,
  saveButtonLabel,
  dataTrackingIdEditButton,
  dataTrackingIdSaveButton,
  dataTrackingIdEditing,
  onSubmit,
}: InlineFormProps) => {
  const methods = useForm();
  const [isEditing, setIsEditing] = React.useState(false);
  const { t } = useTranslation();
  const [status, setStatus] = React.useState<
    "success" | "issue" | "loading" | null
  >(null);

  if (!isEditing) {
    return (
      <div className="InlineForm_container">
        <div className="InlineForm_container--inner">
          <div className="InlineForm_fieldsWrapper">{previewFields}</div>
          <div className="InlineForm_actionsWrapper">
            {status === "success" && (
              <div className="InlineForm_feedback InlineForm_feedback--success">
                {t("ui:InlineForm_Saved", "SAVED")} <Icon icomoon={Icomoon["checkmark"]} />
              </div>
            )}
            {status === "issue" && (
              <div className="InlineForm_feedback InlineForm_feedback--error">
                {t("ui:InlineForm_NotSaved", "NOT SAVED")} <Icon icomoon={Icomoon["notification"]} />
              </div>
            )}

            <button
              className="InlineForm_button link is-size-6"
              onClick={() => setIsEditing(true)}
              data-tracking-id={dataTrackingIdEditButton}
            >
              {editButtonLabel}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setStatus("loading");
          const response = await onSubmit(e);
          setStatus(response);
          setIsEditing(false);
        }}
        className="InlineForm_container InlineForm_container--form"
      >
        <div
          className="InlineForm_container--inner"
          onClick={(ev) => {
            // focus first child input
            const firstInput = ev.currentTarget.querySelector(
              "input, textarea, select"
            );

            if (firstInput) {
              firstInput.focus();
            }
          }}
        >
          <div className="InlineForm_fieldsWrapper InlineForm_fieldsWrapper--form" data-tracking-id={dataTrackingIdEditing}>
            {editingFields}
          </div>
          <div className="InlineForm_actionsWrapper">
            {status === "loading" && (
              <div className="InlineForm_feedback InlineForm_feedback--error">
                <Loader />
              </div>
            )}
            <Button
              type="submit"
              element="button"
              className="InlineForm_button"
              data-tracking-id={dataTrackingIdSaveButton}
            >
              {saveButtonLabel}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
