/* eslint-disable */

import classNames from "classnames";
import React, { ReactNode, useMemo, useState } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { Icomoon, Icon } from "../Icon/Icon";
import { NativeSelect } from "./NativeInputs/select";
import { NativeText } from "./NativeInputs/text";
import { NativeTextarea } from "./NativeInputs/textarea";

import "./wrapper.scss";

type TextElement = {
  input: "input";
} & {
  children?: null;
  props: WithRequired<JSX.IntrinsicElements["input"], "name">;
};

type SelectElement = {
  input: "select";
} & {
  children?: null;
  props: WithRequired<JSX.IntrinsicElements["select"], "name">;
};

type TextareaElement = {
  input: "textarea";
} & {
  children?: null;
  props: WithRequired<JSX.IntrinsicElements["textarea"], "name">;
};

type CustomElement = {
  input: "custom";
} & {
  children: ReactNode;
  props: WithRequired<any, "name">;
};

type InputElement = TextElement | SelectElement | TextareaElement | CustomElement;

type LabelProps = {
  text: string;
  showLabel?: boolean;
  labelBehaviour?: "static" | "material";
};

type InputWrapperProps = {
  id?: HTMLInputElement["id"];
  label: LabelProps;
  helpMessage?: string;
  input: InputElement;
  borderless?: boolean;
  rules?: RegisterOptions;
  icons?: {
    valid?: keyof typeof Icomoon;
    invalid?: keyof typeof Icomoon;
  };
  className?: string;
  dataTrackingId?: string;
};

export const InputWrapper = ({
  label: { text: labelText, showLabel = true, labelBehaviour = "static" },
  helpMessage,
  input: { input: inputElement, children = null, props },
  borderless = false,
  rules,
  icons,
  className,
  dataTrackingId,
}: InputWrapperProps) => {
  const { id, name, ...inputElementProps } = props;
  const { register, formState, getFieldState, watch } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);
  const fieldState = getFieldState(name, formState);
  const currentValue = watch(name);
  const isInvalid = fieldState.invalid && !!fieldState.error?.message;
  const isValid = !isFocused && !!currentValue && !isInvalid;
  const validIcon = icons ? icons?.valid : null;
  const invalidIcon = icons ? icons?.invalid ?? "notification" : null;
  const error = formState.errors[name];
  const internalId = id || `${name}-id`;

  const Component = useMemo(() => {
    const map = {
      custom: null,
      textarea: NativeTextarea,
      select: NativeSelect,
      input: NativeText,
    };
    return map[inputElement as keyof typeof map];
  }, [inputElement]);

  return (
    <div className={classNames("field", className)}>
      <label
        htmlFor={internalId}
        className={classNames("label", {
          "u-visually-hidden": !showLabel,
          "input-wrapper__label--static": labelBehaviour === "static",
          "input-wrapper__label--material": labelBehaviour === "material",
          "input-wrapper__label--focused": isFocused,
          "input-wrapper__label--has-value": !!currentValue,
        })}>
        {labelText}
      </label>
      <div
        className={classNames("control", {
          "has-icons-right": validIcon || invalidIcon,
        })}>
        {!Component ? (
          children
        ) : (
          <Component
            {...inputElementProps}
            className={classNames(inputElementProps?.className, "input-wrapper__input", {
              "input-wrapper__input--errored": error,
              "input-wrapper__input--focused": isFocused || !!currentValue,
              "input-wrapper__input--borderless": borderless,
            })}
            id={internalId}
            {...register(name, {
              ...rules,
              onBlur: (event) => {
                if (rules?.onBlur) rules.onBlur(event);

                setIsFocused(false);
              },
            })}
            onFocus={() => setIsFocused(true)}
            data-tracking-id={dataTrackingId}
          />
        )}
        {!!helpMessage && <div className="is-size-7 mt-1">{helpMessage}</div>}
        {isInvalid && (
          <span className="icon is-small is-right">
            <Icon
              icomoon={Icomoon[invalidIcon]}
              className="is-flex is-justify-content-center is-align-content-center is-flex-wrap-wrap has-icon-red"
            />
          </span>
        )}
        {validIcon && isValid && (
          <span className="icon is-small is-right">
            <Icon
              icomoon={Icomoon[validIcon]}
              className="is-flex is-justify-content-center is-align-content-center is-flex-wrap-wrap has-icon-teal"
            />
          </span>
        )}
      </div>

      {!!error && <div className="help is-danger">{error.message?.toString()}</div>}
    </div>
  );
};
