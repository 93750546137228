import React, { useEffect, useMemo } from "react";
import { BCorpLogo, Link } from "../..";
import { AtolLogo } from "../..";
import classnames from "classnames";
import { useTranslation, Trans } from "react-i18next";
import { useAppState } from "../../utils";
import { Trust, trustCountries } from "../../molecules/Trust/Trust";
import { SubscribeWrapper } from "../SubscribeWrapper/SubscribeWrapper";
import { SocialIcon } from "../../atoms/SocialIcon/SocialIcon";
import "./Footer.scss";

export interface FooterProps {
  bCorpLink?: string;
  hideTrust?: boolean;
  subscribeTfLive?: string;
  subscribeClassName?: string;
  subscribeColumnClassName?: string;
  subscribeTitle?: string;
  subscribeBodyText?: string;
  socialMedia?: Array<{
    label: string;
    platform: string;
    url: string;
  }> | null;
  dataTrackingIds?: {
    travelife?: string;
    destinations?: string;
    collections?: string;
    articles?: string;
    reimagine?: string;
    howItWorks?: string;
    faq?: string;
    privacy?: string;
    cookies?: string;
    terms?: string;
    payment?: string;
    aboutUs?: string;
    becomePartner?: string;
    careers?: string;
    imprint?: string;
    socialMedia?: string;
  }
  isMinimal?: boolean;
}

/**
 * Site footer of the marketing website
 *
 */
export const Footer = (props: FooterProps) => {
  const { t } = useTranslation();
  const { appState } = useAppState();

  const locale = appState?.locale || `en`;
  const isUK = locale === `en-gb`;
  const isEnglish = locale === `en` || locale === `en-gb`;

  const isMinimalFooter = useMemo(
    () => !!props.isMinimal,
    [props.isMinimal]
  );

  const socialMedia = props.socialMedia || [];

  const showTrust = useMemo(
    () =>
      !props.hideTrust && trustCountries.includes(appState?.locale as string),
    [trustCountries, appState?.locale]
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {showTrust && <Trust dataTrackingIds={props.dataTrackingIds} />}
      {!isMinimalFooter && (
        <div className={"subscribe-background"}>
          <SubscribeWrapper
            tfLive={props.subscribeTfLive}
            subscribeClassName={classnames(
              "container is-12-mobile is-10",
              props.subscribeClassName
            )}
            subscribeColumnClassName={props.subscribeColumnClassName}
            title={props.subscribeTitle}
            bodyText={props.subscribeBodyText}
            locale={locale}
            signupSource={"footer"}
            dataTrackingIds={props.dataTrackingIds}
          />
        </div>)}
      <footer className="footer has-text-white">
        <div className="container is-10">
          {!isMinimalFooter && (<>
            <div className="rowWrapper">
              <nav>
                <span className="mb-5">{t("common:discover", "Discover")}</span>
                <ul>
                  <li>
                    <Link href={t("routes:destinations", "/destinations")} data-tracking-id={props.dataTrackingIds?.destinations}>
                      {t("common:pageName_destinations", "Destinations")}
                    </Link>
                  </li>
                  <li>
                    <Link href={t("routes:collections", "/collections")} data-tracking-id={props.dataTrackingIds?.collections}>
                      {t("common:pageName_collections", "Collections")}
                    </Link>
                  </li>
                  <li>
                    <Link href={t("routes:articles", "/articles")} data-tracking-id={props.dataTrackingIds?.articles}>
                      {t("common:pageName_articles", "Articles")}
                    </Link>
                  </li>
                  {isEnglish && (
                    <li>
                      <Link
                        href={t(
                          "routes:reimagineOverview",
                          "https://reimagine.travellocal.com/en"
                        )}
                        data-tracking-id={props.dataTrackingIds?.reimagine}
                      >
                        {t(
                          "common:pageName_reimaginingTravel",
                          "Reimagining travel"
                        )}
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link href={t("routes:howItWorks", "/how-it-works")} data-tracking-id={props.dataTrackingIds?.howItWorks}>
                      {t("common:pageName_howItWorks", "How it works")}
                    </Link>
                  </li>
                </ul>
              </nav>

              <nav>
                <span className="mb-5">{t("common:support", "Support")}</span>
                <ul>
                  <li>
                    <Link href={t("routes:faq", "/about-us/faq")} data-tracking-id={props.dataTrackingIds?.faq}>
                      {t("common:pageName_faq", "FAQ")}
                    </Link>
                  </li>
                  <li>
                    <Link href={t("routes:privacy", "/privacy")} data-tracking-id={props.dataTrackingIds?.privacy}>
                      {t("common:pageName_privacy", "Privacy")}
                    </Link>
                  </li>
                  <li>
                    <Link href={t("routes:cookiePolicy", "/cookies")} data-tracking-id={props.dataTrackingIds?.cookies}>
                      {t("common:pageName_cookiePolicy", "Cookie policy")}
                    </Link>
                  </li>
                  <li>
                    <Link href={t("routes:termsOfUse", "/terms")} data-tracking-id={props.dataTrackingIds?.terms}>
                      {t("common:pageName_termsOfUse", "Terms")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={t(
                        "routes:payment",
                        "/about-us/faq#payments-currencies-and-fees"
                      )}
                      data-tracking-id={props.dataTrackingIds?.payment}
                    >
                      {t("common:pageName_payment", "Payment")}
                    </Link>
                  </li>
                </ul>
              </nav>

              <nav>
                <span className="mb-5">TravelLocal</span>
                <ul>
                  <li>
                    <Link href={t("routes:about", "/about-us")} data-tracking-id={props.dataTrackingIds?.aboutUs}>
                      {t("common:pageName_about", "About us")}
                    </Link>
                  </li>
                  <li>
                    <Link href={t("routes:partnerWithUs", "/become-a-partner")} data-tracking-id={props.dataTrackingIds?.becomePartner}>
                      {t("common:pageName_partnerWithUs", "Become a partner")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={t(
                        "routes:careers",
                        "https://careers.travellocal.com/"
                      )}
                      data-tracking-id={props.dataTrackingIds?.careers}
                    >
                      {t("common:pageName_careers", "Careers")}
                    </Link>
                  </li>
                  <li>
                    <Link href={t("routes:imprint", "/about-us/imprint")} data-tracking-id={props.dataTrackingIds?.imprint}>
                      {t("common:pageName_imprint", "Imprint")}
                    </Link>
                  </li>
                </ul>
              </nav>

              <div className="globalTravel">
                <p className="blurb">
                  {t(
                    "common:globalTravelLocalExperts",
                    "Global travel, local experts"
                  )}
                </p>
                {socialMedia.length > 0 && (
                  <div className="socialmediaWrapper">
                    {socialMedia.map((sm) => {
                      return (
                        <div key={sm.label} className="socialmediaItem">
                          <Link
                            href={sm.url}
                            key={sm.label}
                            alt={sm.label}
                            title={sm.label}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-tracking-id={`${props.dataTrackingIds?.socialMedia}.${sm.label.toLowerCase()}`}
                          >
                            <SocialIcon icon={sm.platform} fill="#D0D4DB" />
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                )}
                {isEnglish && props.bCorpLink ? (
                  <Link
                    href={props.bCorpLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BCorpLogo />
                  </Link>
                ) : (
                  <BCorpLogo />
                )}
              </div>
            </div>

            <hr />

            {isUK && (
              <div className="is-flex-tablet is-justify-content-space-between">
                <div className="atol-info">
                  <AtolLogo type="light" />
                  <div className="atol-info__text">
                    <Trans i18nKey="Footer_ATOL">
                      <p>TravelLocal is registered with ATOL, no. 11445</p>
                      <p>
                        Some of the flights and flight-inclusive holidays on this
                        website are financially protected by the ATOL scheme. But
                        ATOL protection does not apply to all holiday and travel
                        services listed on this website. Please ask us to confirm
                        what protection may apply to your booking. If you do not
                        receive an ATOL Certificate then the booking will not be
                        ATOL protected. If you do receive an ATOL Certificate but
                        all the parts of your trip are not listed on it, those
                        parts will not be ATOL protected. Please see our booking
                        conditions for information, or for more information about
                        financial protection and the ATOL Certificate go to:
                        www.atol.org.uk/ATOLCertificate
                      </p>
                      <p>
                        TravelLocal Limited, a company registered in England and
                        Wales under company number 07186581.
                      </p>
                      <p>
                        Our registered office is 2nd Floor, Nucleus House, 2 Lower
                        Mortlake Rd, Richmond, TW9 2JA. Telephone +44 (0)117 325
                        7898, email team@travellocal.com.
                      </p>
                    </Trans>
                  </div>
                </div>
              </div>
            )}
          </>)}

          <div className="copyright">
            {t("common:copyright", "© 2024 TravelLocal - All rights reserved", {
              year: new Date().getFullYear().toString(),
            })}
          </div>
        </div>
      </footer>
    </>
  );
};
